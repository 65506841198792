import { handleErrorWithSentry } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
  dsn: 'https://9e208f357d45f620bee54b81b891ccdc@o4508283963899904.ingest.de.sentry.io/4508283965931600',
  tracesSampleRate: 0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
