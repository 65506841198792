import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~4],
		"/admin": [5],
		"/biodata/[hash]": [~6],
		"/filters": [~7],
		"/forms/benefits": [8],
		"/forms/details/page1": [~9,[2]],
		"/forms/details/page2": [~10,[2]],
		"/forms/details/page3": [~11,[2]],
		"/forms/details/page4": [~12,[2]],
		"/forms/details/page5": [~13,[2]],
		"/forms/details/page6": [~14,[2]],
		"/forms/details/page7": [~15,[2]],
		"/forms/gender": [~16],
		"/forms/onboarding/page1": [~17,[3]],
		"/forms/onboarding/page2": [~18,[3]],
		"/forms/onboarding/page3": [~19,[3]],
		"/forms/onboarding/page4": [~20,[3]],
		"/forms/onboarding/page5": [~21,[3]],
		"/forms/onboarding/page6": [~22,[3]],
		"/forms/religion": [~23],
		"/home": [~24],
		"/inbox": [~25],
		"/inbox/conversations/[id]": [~26],
		"/login": [27],
		"/login/otp/[phoneNo]": [~28],
		"/payment/plans": [~29],
		"/payment/status/FAILED": [30],
		"/payment/status/PENDING": [31],
		"/payment/status/SUCCESS": [~32],
		"/payment/topup": [~33],
		"/privacy/phone": [~34],
		"/profiles/[id]": [~35],
		"/restore": [36],
		"/shortlists": [~37],
		"/support": [~38],
		"/views": [~39],
		"/vip": [~40]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';